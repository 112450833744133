import { colors } from '.'
export const themesOptions = {
  components: {
    styleOverrides: {
      MuiDivider: {
        fullWidth: {
          width: '100%'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          },
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      }
    },
  },
  palette: {
    action: {
      active: '#6b778c'
    },
    background: {
      default: '#F5F5F5',
      paper: '#ffffff'
    },
    error: {
      contrastText: '#ffffff',
      main: '#E60000'
    },
    mode: 'light',
    primary: {
      main: '#98235C',
    },
    secondary: {
      main: '#EC4252',
    },
    success: {
      contrastText: '#ffffff',
      main: '#009900'
    },
    text: {
      primary: '#314155',
      secondary: '#fff'
    },
    warning: {
      contrastText: '#ffffff',
      main: '#EB9700'
    },
    info: {
      contrastText: '#ffffff',
      main: '#CCCCCC'
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: ' Roboto',
    h1: {
      fontWeight: 600,
      fontSize: 70
    },
    h2: {
      fontWeight: 600,
      fontSize: 48
    },
    h3: {
      fontWeight: 600,
      fontSize: 32
    },
    h4: {
      fontWeight: 600,
      fontSize: 28
    },
    h5: {
      fontWeight: 600,
      fontSize: 25
    },
    h6: {
      fontWeight: 600,
      fontSize: 20
    },
    body1: {
      fontSize: 20
    },
    // body2: {
    //   fontSize: '1.25rem' // 20
    // },
    subtitle1: {
      fontSize: 18
    },
    subtitle2: {
      fontSize: 16
    },
    overline: {
      fontWeight: 600
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.footnote
      }
    },
    MuiRadio: {
      root: {
        color: colors.primary
      }
    }
  }
}