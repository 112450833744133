import React from 'react'
import {  IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useMenu } from '../../Global'
import MenuIcon from '@material-ui/icons/Menu'
import { colors } from '../../Themes'
import {  oneUp } from '../../Themes/Images'
import useStyles from './Layout.styles'

const Layout = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    toggleDrawer,
  } = useMenu()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(760))

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <img src={oneUp} style={{ cursor: 'pointer', objectFit: 'contain', height: 'auto', width: '40px' }} onClick={() => history.push('/dashboard')} />
      </div>
      <div className={classes.iconContainer}>
        {isMobile &&
          <IconButton
            disableRipple={false}
            style={{ backgroundColor: 'transparent', color: colors.white }}
            disableFocusRipple
            centerRipple={true}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        }
      </div>
    </div>
  )
}

export default Layout