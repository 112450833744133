import React from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loading from 'react-lottie'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'
import { loadingDataplayer } from '../../Themes/Images'
import { pressableStyles, styles } from './Button.styles'

const LOADING_DATAPLAYER = {
  loop: true,
  autoplay: true,
  animationData: loadingDataplayer,
  rendererSettings: {
    className: 'xMidYMid slice',
  },
}

const PrimeButton = withStyles(styles)(props => <Button {...props} />)

const Pressable = withStyles(pressableStyles)(props => <ButtonBase {...props} />)


const PrimaryButton=({ children, loading, ...rest })=>{
  return (
    <PrimeButton
      {...rest} 
    >
      {loading ? (
        <div>
          {/* <Loading options={LOADING_DATAPLAYER} height={40} /> */}
        </div>
      ) : children}
    </PrimeButton>
  )
}
PrimaryButton.propTypes={
  loading: PropTypes.bool
}
PrimaryButton.defaultProps = {
  loading: false,
}

export {
  PrimaryButton,
  Pressable
}


