/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { List, Divider, IconButton, Hidden } from '@material-ui/core'
import { Route, Link, Redirect, Switch, withRouter, useHistory } from 'react-router-dom'
import { Drawer, DrawerListItem } from '../../Components'
import {
  DashboardIcon,
  LogoutIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  oneUp,
} from '../../Themes/Images'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { useAuth, useMenu } from '../../Global'
import { useWindowDimensions } from '../../Utils'
import Dashboard from '../Dashboard/Dashboard'
import NotFound from '../NotFound/NotFound'
import {  useStyles } from './Wrapper.styles'
import { PrimaryButton } from '../../Components/Button/Button'
import { Axios } from '../../Config'

const DRAWER_ITEMS = [
  {
    title: 'Dashboard',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

function MiniDrawer() {
  const classes = useStyles()
  const history = useHistory()
  const {
    logout,
  } = useAuth()
  const { width } = useWindowDimensions()
  const {
    selectedItem,
    isVisible,
    isExpanded,
    isFullScreen,
    toggleDrawer,
    toggleDrawerExpansion,
    setSelectedRoute,
  } = useMenu()

  const current = window.location.pathname

  useEffect(() => {
    setSelectedRoute(current)
  }, [current])

  const refresh = async() => {
    try {
      await Axios.post('/prod/refreshDashboard')
  }
  catch (e) {
    console.log(e)
  }
    
  }


  return (
    <Switch>
      <Route
        path={[
          '/dashboard',
        ]}
      >
        <div className={classes.root}>
          <div className={isFullScreen ? 'drawerWrapper' : ''}>
            <Hidden xsDown>
              <IconButton
              style={{ position: 'fixed' }}
                disableFocusRipple
                centerRipple={true}
                onClick={toggleDrawerExpansion}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: isExpanded,
                  [classes.drawerClose]: !isExpanded,
                })}
              >
                {isExpanded ? (
                  <ArrowLeftIcon fill="#58C2D7"  className="toggleDrawerIcon" />
                ) : (
                  <ArrowRightIcon fill="#58C2D7" className="toggleDrawerIcon" />
                )}
              </IconButton>
            </Hidden>
            <Drawer
              onOpen={toggleDrawer}
              onClose={toggleDrawer}
              variant={width > 760 ? 'permanent' : 'temporary'}
              isExpanded={isExpanded}
              isVisible={isVisible}
            >
              <Hidden smUp>
               <div className="logoContainer">
               <div style={{ display: 'flex' }}>
               {(isExpanded || width <= 760) && (
                  <img
                    src={oneUp}
                    className="logoName"
                    onClick={() => {
                      history.push('/dashboard')
                      toggleDrawer()
                    }}
                  />
                )}
                </div>
                  <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    classes={{ root: classes.iconButton }}
                    onClick={toggleDrawer}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
              </div> 
              </Hidden>
              <div className="drawerContent">
                <div>
                  <Divider className="divider" variant="middle" />
                  <List>
                    {DRAWER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                        
                      />
                    ))}
                  </List>
                  <div style={{ display: 'flex', paddingLeft: 18 }}>
                  {/* <PrimaryButton onClick={refresh} style={{ background: '#58C2D7', width: 120 }}>Refresh</PrimaryButton> */}
                  </div>
                 
                </div>
                <div>
                  <Divider className="divider" variant="middle" />
                  <DrawerListItem
                    index={9999}
                    title="Log out"
                    Icon={LogoutIcon}
                    isActive={true}
                    withTitle={isExpanded || width <= 760}
                    logout
                    onClick={logout}
                  />
                </div>
              </div>
            </Drawer>
          </div>
          <main className={classes.content}>
            <div className="homeContent">
              <Route exact path="/dashboard" component={Dashboard} />
            </div>
          </main>
        </div>
      </Route>
      <Redirect to="/dashboard" path={['/', '/reset-password', '/login']} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default withRouter(MiniDrawer)
