/* eslint-disable promise/always-return */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth as AmplifyAuth } from 'aws-amplify'
import { Axios } from '../Config'
import { useHistory } from 'react-router-dom'
const Auth = createContext()

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    user: '',
    picture: '',
    userAttributes: '',
    data_loading: '',
    tooltips: '',
    website_locked: '',
    is_trialing: undefined,
    plan: '',
    roles: [],
    token: undefined,
    isAuthenticated: false,
    isLoading: true,
    referralCode: '',
    
  })
  // When user is not from cliniko
  const [shouldLogin, setShouldLogin] = useState(true)
  // When user send a email for upgrade for the message at dashboard
  const [upgradeSucces, setUpgradeSuccess] = useState(false)

  const [userAcc, setUserAcc] = useState()
  
  const [loginValues, setLoginValues] = useState({
    isLoginIn: false,
    loginError: null
  })

  const [authCodeValues, setAUthCodeValues] = useState({
    isCodeIn: false,
    codeError: null
  })

  const history = useHistory()

  const logout = () => {
    AmplifyAuth.signOut()
      .then((res) => {
        setAuth({
          user: undefined,
          picture: undefined,
          userAttributes: undefined,
          data_loading: '',
          tooltips: '',
          website_locked: '',
          isAuthenticated: false,
          isLoading: false,
          referralCode: '',
     
        })
      })
      .catch(() => { })
  }

  
  const login = async (username, password, success) => {
    setLoginValues((prev)=>{
      return {
        ...prev,
        isLoginIn: true

      }
    })
    try {
      const  user = await  AmplifyAuth.signIn(username, password)
      setUserAcc(user)  
      if (user?.challengeName === 'CUSTOM_CHALLENGE') {
        success()
      } else {
        setAuth({
          user: user?.username,
          picture: user?.attributes?.picture,
          token: user?.signInUserSession?.idToken?.jwtToken,
          userAttributes: user?.attributes,
          roles: user?.signInUserSession?.accessToken?.payload['cognito:groups']|| [],
          isAuthenticated: true,
          isLoading: false,
        })
        
     
      }
     
      setLoginValues((prev)=>{
        return {
          ...prev,
          isLoginIn: false
    
        }
      })
    
      // const data = await AmplifyAuth.sendCustomChallengeAnswer(user, '000000')
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
       
      })
      setLoginValues({
        isLoginIn: false,
        loginError: ' The username and/or password you entered did not match our records. Please double-check and try again. '
      })
    }

  }

  const verify = async (code, errorInput) => {
    setAUthCodeValues((prev)=>{
      return {
        ...prev,
        isCodeIn: true

      }
    })
    try {
      const data = await AmplifyAuth.sendCustomChallengeAnswer(userAcc, code)
      setAuth({
        user: data?.username,
        picture: data?.attributes?.picture,
        token: data?.signInUserSession.idToken.jwtToken,
        userAttributes: data?.attributes,
        roles: data.signInUserSession.accessToken.payload['cognito:groups']|| [],
        isAuthenticated: true,
        isLoading: false,
      })
      setAUthCodeValues((prev)=>{
        return {
          ...prev,
          isCodeIn: false
    
        }
      })
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
       
      })
      setAUthCodeValues({
        isCodeIn: false,
        codeError: 'The code you entered did not match our records. Please double-check and try again.'
      })
      errorInput()
    }

  }




  const getAuthenticatedUser = async (userData) => {
    if (!auth.isAuthenticated) {
      try {
        let data
        if (userData){
          data=userData
        } else {
          data = await AmplifyAuth.currentAuthenticatedUser({ bypassCache: true })
        }
        // let { data: costumUserAtrributes } = await Axios.get('/prod/user')
        setAuth({
          user: data?.username,
          picture: data?.attributes?.picture,
          token: data?.signInUserSession.idToken.jwtToken,
          userAttributes: data?.attributes,
          roles: data.signInUserSession.accessToken.payload['cognito:groups'] || [],
          isAuthenticated: true,
          isLoading: false,
        })
      } catch (error) {
        setAuth({
          isAuthenticated: false,
          isLoading: false,
          user: '',
          picture: '',
          userAttributes: '',
          data_loading: '',
          tooltips: '',
          website_locked: '',
          roles: [],
          token: undefined,
          referralCode: '',
        
        })
      }
    }
  }
  const getUpdatedUser = async () => {
    try {
      let data = await AmplifyAuth.currentAuthenticatedUser({ bypassCache: true })
      // let { data: costumUserAtrributes } = await Axios.get('/prod/user')
      setAuth({
        user: data?.username,
        picture: data?.attributes?.picture,
        token: data?.signInUserSession.idToken.jwtToken,
        userAttributes: data?.attributes,
        roles: data.signInUserSession.accessToken.payload['cognito:groups']|| [],
        isAuthenticated: true,
        isLoading: false,
      })
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
      
      })
    }
  }

  const setPicture = (imageUrl) => {
    setAuth({ ...auth, picture: imageUrl })
  }

  useEffect(() => {
    getAuthenticatedUser()
  }, [])

  return (
    <Auth.Provider
      value={{
        ...auth,
        ...loginValues,
        setLoginValues,
        ...authCodeValues,
        setAUthCodeValues,
        login,
        logout,
        getAuthenticatedUser,
        getUpdatedUser,
        setPicture,
        shouldLogin,
        setShouldLogin,
        upgradeSucces,
        setUpgradeSuccess,
        verify,
        userAcc
      }}
    >
      {children}
    </Auth.Provider>
  )
}
AuthProvider.propTypes = {
  children: PropTypes.any,
}

function useAuth() {
  const context = useContext(Auth)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { useAuth }
export default AuthProvider