import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 'light',
    color: '#5C5C5C',
    marginBottom: 15,
  },
  rootBackIcon: {
    fill: colors.secondary,
    width: 28,
    height: 28,
  },
  loginButton: {
    width: '100%',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%',
    },
  },
}))

export default useStyles