import React from 'react'
import Loading from 'react-lottie'
import { loading, loading2 } from '../../Themes/Images'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    className: 'loading',
  },
}

function Loader() {
  return (
    <div className="loadingContainer">
      <Loading options={LOADING_OPTIONS} height={180} />
    </div>
  )
}


const LOADING2_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loading2,
  rendererSettings: {
    className: 'loading',
  },
}
function SecondLoader() {
  return (
    <div className="loadingContainer">
      <Loading options={LOADING2_OPTIONS} height={180} />
    </div>
  )
}
export default Loader
export { SecondLoader }