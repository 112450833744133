import React from 'react'
import '../../App.css'

function ContentContainer({ children, header, dashboard }) {
  if (dashboard) {
    return (
      <div className="subHeader2">
        {header}
      </div>
    )
  }
  return (
    <div className="contentContainerWrapper">
      <div className="subHeader" >
        {header}
      </div>
      <div className="contentContainer">
        {children}
      </div>
    </div>
  )
}

export default ContentContainer