/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext,  useContext, useState } from 'react'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import Axios from '../Config/Axios'
import { useAuth } from './Auth'

const Subscription = createContext()


function SubscriptionProvider({ children }) {
  const { user }=useAuth()
  const [refreshSubId, setRefreshSubId] = useState(0)
  const [refreshPlanId, setRefreshPlanId] = useState(0)
  const refetchSubscription = () => setRefreshSubId(prev => prev+1)
  const refetchPlan = () => setRefreshPlanId(prev => prev+1)

  return (
    <Subscription.Provider
      value={{
        refetchSubscription,
        refetchPlan,
      }}
    >
      {children}
    </Subscription.Provider>
  )
}
SubscriptionProvider.propTypes = {
  children: PropTypes.any,
}

function useSubscription() {
  const context = useContext(Subscription)
  if (context === undefined) {
    throw new Error('useSub must be used within an SubProvider')
  }
  return context
}

export { useSubscription }
export default SubscriptionProvider
