import React from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const styles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: 5,
    // paddingLeft: 30,
    // paddingRight: 30,
    // paddingTop: 10,
    // paddingBottom: 10,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
}
  
const pressableStyles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: 5,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
}


export { styles, pressableStyles }