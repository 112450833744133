import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import ContentContainer from '../ContentContainer/ContentContainer'
import { Skeleton } from '@material-ui/lab'
import useStylesSkeletonDashboard from './SkeletonDashboard.styles'

const SkeletonDashboard = () => {
  const classes = useStylesSkeletonDashboard()
  const width = '20%'
  const width2 = '100%'
  const width3 = '25%'
  const width4 = '75%'

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down(1162))
  
  return (
    <ContentContainer> 
      <div style={{ width: '100%' }}>
        <div className={classes.flex2}>
          <Skeleton classes={{ root: classes.root2 }} width={width} height={25} />
          <Skeleton classes={{ root: classes.root2 }} width={width} height={25} />
          <Skeleton classes={{ root: classes.root2 }} width={width} height={25} />
          <Skeleton classes={{ root: classes.root2 }} width={width} height={25} />
          <Skeleton classes={{ root: classes.root2 }} width={width} height={25} />
        </div>
        <div className={classes.flex3}>
          <Skeleton classes={{ root: classes.root2 }} width={width2} height={100} />
        </div>
        <div className={classes.flex4}>
          {isMobile ? 
            <Skeleton classes={{ root: classes.root2 }} width={width2} height={150} /> 
            :
            <>
              <Skeleton classes={{ root: classes.root2 }} width={width3} height={150} />
              <Skeleton classes={{ root: classes.root2 }} width={width3} height={150} />
              <Skeleton classes={{ root: classes.root2 }} width={width3} height={150} />
              <Skeleton classes={{ root: classes.root2 }} width={width3} height={150} />
            </> }
        </div>
        <div className={classes.flex5}>
          <Skeleton classes={{ root: classes.root2 }} width={width2} height={800} />
        </div>
        <div className={classes.flex6}>
          {isMobile ? <Skeleton classes={{ root: classes.root2 }} width={width2} height={200} /> : 
            <>
              <Skeleton classes={{ root: classes.root2 }} width={width3} height={200} />
              <Skeleton classes={{ root: classes.root2 }} width={width4} height={200} />
            </>
          }
         
        </div>
      </div>
    </ContentContainer>
  )
}

export default SkeletonDashboard