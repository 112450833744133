const amplifyConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_NEW_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_NEW_WEB_CLIENT_ID,
    region: process.env.REACT_APP_NEW_COGNITO_REGION,
    authenticationFlowType: process.env.REACT_APP_FLOWTYPE
  },

};

export default amplifyConfig;
