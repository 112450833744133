const styles = {
  default: {
    fontSize: 16,
    color: 'rgba(49, 65, 85, 1)',
    fontFamily: 'Roboto',
    fontWeight: '400',
  },
  primary: {
    color: 'rgba(152, 35, 92, 1)'
  },
  secondary: {
    color: 'rgba(236, 66, 82, 1)'
  },
  bodyColor: {
    color: 'rgba(49, 65, 85, 1)'
  },
  defaultTablet: {
    fontSize: 20,
  },
  defaultDesktop: {
    fontSize: 26,
  },
  light: {
    fontFamily: 'Roboto',
    fontWeight: '300'
  },
  regular: {
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: '700'
  },
  extraBold: {
    fontFamily: 'Roboto',
    fontWeight: '900'
  },
  medium: {
    fontFamily: 'Roboto',
    fontWeight: '500'
  },
  thin: {
    fontFamily: 'Roboto',
    fontWeight: '100'
  },
  h1: {
    mobile: {
      fontSize: 28
    },
    tablet: {
      fontSize: 48
    },
    desktop: {
      fontSize: 70
    },
  },
  h2: {
    mobile: {
      fontSize: 24
    },
    tablet: {
      fontSize: 32
    },
    desktop: {
      fontSize: 48
    },
  },
  h3: {
    mobile: {
      fontSize: 22
    },
    tablet: {
      fontSize: 28
    },
    desktop: {
      fontSize: 32
    },
  },
  h4: {
    mobile: {
      fontSize: 18
    },
    tablet: {
      fontSize: 25
    },
    desktop: {
      fontSize: 28
    },
  },
  h5: {
    mobile: {
      fontSize: 16
    },
    tablet: {
      fontSize: 20
    },
    desktop: {
      fontSize: 25
    },
  },
  h6: {
    mobile: {
      fontSize: 16
    },
    tablet: {
      fontSize: 20
    },
    desktop: {
      fontSize: 22
    },
  },
  body: {
    mobile: {
      fontSize: 16
    },
    tablet: {
      fontSize: 18
    },
    desktop: {
      fontSize: 20
    },
  },
  subnote: {
    mobile: {
      fontSize: 14
    },
    tablet: {
      fontSize: 16
    },
    desktop: {
      fontSize: 18
    },
  },
  footnote: {
    mobile: {
      fontSize: 14
    },
    tablet: {
      fontSize: 16
    },
    desktop: {
      fontSize: 16
    },
  },
  small: {
    mobile: {
      fontSize: 14
    },
    tablet: {
      fontSize: 14
    },
    desktop: {
      fontSize: 14
    },
  },
  
}
 
export default styles

