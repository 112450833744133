/* eslint-disable camelcase */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import Axios from '../../Config/Axios'
import { useMenu, useAuth, useSubscription } from '../../Global'
import {  Hidden, IconButton } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import '../../App.css'
import { ContentContainer, Text } from '../../Components'
import SkeletonDashboard from '../../Components/SkeletonDashboard'
import useStyles from './Dashboard.styles'


function Dashboard( ) {
  const divRef = useRef()
  const classes = useStyles()
  const {  getUpdatedUser,  dashboard_id, is_trialing, data_loading, user } = useAuth()
  const { isFullScreen, toggleFullScreen } = useMenu()
  const { refetchSubscription } = useSubscription()
  const [isLoading, setLoading] = useState(true)
  // const [isDataLoading, setDataLoading] = useState(false)

  useEffect(() => {
    getUpdatedUser()
    refetchSubscription()    
  }, [])


  useEffect(() => {
    {
      Axios({
        method: 'GET',
        url: '/prod/getDashboardEmbedUrl',
      })
        .then((response) => {
          setLoading(false)
          QuickSightEmbedding.embedDashboard({
            url: response?.data?.url,
            container: divRef.current,
            scrolling: 'no',
            height: '100%',
            width: '100%',
            printEnabled: true
          })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <ContentContainer dashboard
        header={
          <div className={classes.subheader1}>
            <Text size='subnote' weight='bold' className="sectionTitle">Dashboard</Text>
          </div>
        }
      />

      {isLoading ? (
        <SkeletonDashboard />
      ) : (
        <div
          className={
            isFullScreen ? classes.dashboardFullScreen : classes.dashboard
          }
        >
          <Hidden xsDown>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              classes={{
                root: isFullScreen
                  ? classes.iconButtonFull
                  : classes.iconButton,
              }}
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <FullscreenExitIcon classes={{ root: classes.rootBackIcon }} />
              ) : (
                <FullscreenIcon classes={{ root: classes.rootBackIcon }} />
              )}
            </IconButton>
          </Hidden>

          <div ref={divRef} style={{ flex: 2 }} />
        </div>
      )}
    </>
  )
}

export default Dashboard