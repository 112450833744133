import { makeStyles } from '@material-ui/core'

const useStylesSkeletonDashboard = makeStyles({
  root2: {
    background: '#c9c9c9',
    borderRadius: 5
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start'
  },
  flex2: {
    display: 'flex',
    gap: 20,
    width: '50%',
    justifyContent: 'flex-start'
    // marginBottom: 20
  },
  flex3: {
    display: 'flex',
    width: '100%'
  },
  flex4: {
    display: 'flex',
    marginTop: -20,
    gap: 20,
    width: '100%'
  },
  flex5: {
    display: 'flex',
    marginTop: -180,
    width: '100%'
  },
  flex6: {
    display: 'flex',
    marginTop: -140,
    gap: 20,
    width: '100%'
  }
})

export default useStylesSkeletonDashboard