import React from 'react'
import { Password, RenderPasswordCondition } from '../../Components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { colors } from '../../Themes'
import { PrimaryButton } from '../../Components/Button/Button'
import useStyles from './NewPassword.styles'

function NewPassword({
  newPassword,
  confirmPassword,
  setConfirmPassword,
  error,
  submitChanges,
  validatePassowrd,
  setValidatePassword,
  onChangePassword
}) {
  const classes = useStyles()
  
  return (
    <>
      <h1 style={{ color: colors.accent }}>Set new password</h1>
      <span className={classes.description}>
        Enter the email connected to this account and we will send you an email
        with the instructions to reset your password.
      </span>
      <Password
        label="Password"
        value={newPassword}
        setValue={onChangePassword}
        onFocus={() =>
          setValidatePassword((prev) => ({
            ...prev,
            focused: true,
            isVisible: true,
          }))
        }
        onBlur={() =>
          setValidatePassword((prev) => ({
            ...prev,
            focused: false,
          }))
        }
      />
      {validatePassowrd?.isVisible && <RenderPasswordCondition validatePassowrd={validatePassowrd} />}
      <Password
        label="Password"
        value={confirmPassword}
        setValue={setConfirmPassword}
      />
      
      {Boolean(error) && <span className="loginError">{error}</span>}
      <PrimaryButton
        style={{ marginTop: 30, backgroundColor: colors.accent }}
        className={classes.loginButton}
        onClick={submitChanges}
      >
        Save
      </PrimaryButton>
      <Link
        style={{
          display: 'flex',
          alignSelf: 'flex-start',
          textDecoration: 'none',
          color: colors.secondary,
          marginTop: 15,
          alignItems: 'center',
        }}
        to="/login"
      >
        <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
        <span className="logoutDrawer">Back to Login</span>
      </Link>
    </>
  )
}

export default NewPassword
