/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

const DEFAULT_DRAWER_CONFIG = {
  isExpanded: true,
  isVisible: false,
  isFullScreen: false,
}

const Menu = createContext()

function MenuProvider({ children }) {
  const current = window.location.pathname
  const [menu, setMenu] = useState(() => {
    const menuConfig = JSON.parse(
      localStorage.getItem('menu') || JSON.stringify(DEFAULT_DRAWER_CONFIG)
    )
    return {
      ...menuConfig,
      selectedItem: current,
    }
  })

  const toggleFullScreen = () => {
    setMenu((prev) => {
      const next = {
        ...prev,
        isFullScreen: !prev.isFullScreen,
      }
      localStorage.setItem('menu', JSON.stringify(next))
      return next
    })
  }

  const toggleDrawer = () => {
    setMenu((prev) => {
      const next = {
        ...prev,
        isVisible: !prev.isVisible,
      }
      localStorage.setItem('menu', JSON.stringify(next))
      return next
    })
  }

  const toggleDrawerExpansion = () => {
    setMenu((prev) => {
      const next = {
        ...prev,
        isExpanded: !prev.isExpanded,
      }
      localStorage.setItem('menu', JSON.stringify(next))
      return next
    })
  }
  const toggleDrawerOnHelpCenterExpansion = () => {
    setMenu((prev) => {
      const next = {
        ...prev,
        isExpanded: true
      }
      localStorage.setItem('menu', JSON.stringify(next))
      return next
    })
  }
  const setSelectedRoute = (selectedItem) => {
    setMenu((prev) => {
      const next = {
        ...prev,
        selectedItem,
      }
      localStorage.setItem('menu', JSON.stringify(next))
      return next
    })
  }

  return (
    <Menu.Provider
      value={{
        ...menu,
        toggleFullScreen,
        toggleDrawer,
        toggleDrawerExpansion,
        toggleDrawerOnHelpCenterExpansion,
        setSelectedRoute,
      }}
    >
      {children}
    </Menu.Provider>
  )
}
MenuProvider.propTypes = {
  children: PropTypes.any,
}

function useMenu() {
  const context = useContext(Menu)
  if (context === undefined) {
    throw new Error('useMenu must be used within an MenuProvider')
  }
  return context
}

export { useMenu }
export default MenuProvider
