import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { DRAWER_WIDTH } from '../../Themes/Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  drawerOpen: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: DRAWER_WIDTH - 20,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
  drawerClose: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: 52.5,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
}))
  
const selectStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    flex: 1,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: colors.background,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}

export { useStyles, selectStyles }