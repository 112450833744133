/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Amplify from 'aws-amplify'
import { amplifyConfig } from './Config'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Wrapper, Login, NotFound,  ForgotPassword } from './Screens'
import { Loader } from './Components'
import { AuthProvider, useAuth, MenuProvider, SubscriptionProvider } from './Global'
import { themesOptions } from './Themes'
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom'
import './App.css'
import Layout from './Components/Layout'
import Verify from './Screens/VerifyCode/VerifyCode'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
    }
  }
})
Amplify.configure(amplifyConfig)

const theme = createTheme(themesOptions)

function UnAuthenticatedContent() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/verify" component={Verify} />
      <Route exact path="/reset-password" component={ForgotPassword} />
    

      <Redirect
        to="/login"
        path={['/dashboard', '/', '/account', '/settings']}
      />
      <Route component={NotFound} />
    </Switch>
  )
}

function AppContent() {
  const { isLoading, isAuthenticated, roles } = useAuth()
  if (isLoading) {
    return <Loader />
  }

  if (isAuthenticated) {
    return (
      <MenuProvider>
        <SubscriptionProvider>
          <Layout />
          <Wrapper />
        </SubscriptionProvider>
      </MenuProvider>
    )
  }
  if (!isAuthenticated) {
    return <UnAuthenticatedContent />
  }


}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router  >
            <AppContent />
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
