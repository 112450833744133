import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  rootIcon: {
    fill: colors.white,
    width: 40,
    height: 40,
  },
  rootBackIcon: {
    fill: colors.primary,
    width: 24,
    height: 24,
  },
  iconButtonFull: {
    position: 'absolute',
    right: 33,
    padding: '10px 5px',
  },
  iconButton: {
    position: 'absolute',
    right: 50,
    top: 5,
    padding: 5,
  },
  dashboard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingInline: 24,
    [theme.breakpoints.down(760)]: {
      paddingInline: 10,
    },
  },
  dashboardFullScreen: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  // Loading Data styles
  loadingContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    paddingTop: 30,
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
  loadingDataMessage: {
    width: '50%',
    backgroundColor: colors.white,
    padding: 20,
    border: '1px solid ' + colors.white,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  lottie: {
    height: 700,
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  subheader1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
}))

export default useStyles