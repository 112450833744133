import axios from "axios";
import { Auth } from "aws-amplify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  /* other custom settings */
});

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                Auth.signOut();
              } else {
                config.headers.Authorization =
                  "Bearer " + data.getIdToken().getJwtToken();
                resolve(config);
              }
            });
          });
        } else {
          config.headers.Authorization =
            "Bearer " + session.getIdToken().getJwtToken();
          resolve(config);
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

export default instance;
