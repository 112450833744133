import React from 'react'
import clsx from 'clsx'
import {
  SwipeableDrawer as MuiDrawer,
  ListItem,
  ListItemIcon,
} from '@material-ui/core'
import useStyles from './Drawer.styles'

function Drawer({ isExpanded, isVisible, children, ...rest }) {
  const classes = useStyles()

  return (
    <MuiDrawer
      open={isVisible}
      BackdropProps={{ invisible: true }}
      className={clsx(classes.drawer, {
        [classes.drawerBgOpen]: isExpanded,
        [classes.drawerBgClose]: !isExpanded,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isExpanded,
          [classes.drawerClose]: !isExpanded,
        }),
      }}
      {...rest}
    >
      {children}
    </MuiDrawer>
  )
}

function DrawerListItem({
  index,
  title,
  Icon,
  isActive,
  withTitle,
  onClick,
  component,
  to,
  logout
}) {
  return (
    <ListItem
      button
      key={index + title}
      onClick={onClick}
      component={component}
      to={to}
      className={!isActive ? 'itemInactive' : undefined}
      disableGutters
    >
      <ListItemIcon className="listItemIcon">
        <Icon className="listIcon" />
      </ListItemIcon>
      {withTitle && (
        <span
          className={
            (isActive && logout )? 'logout' : isActive ? 'listItemTitle listItemTitleActive' : 'listItemTitle'
          }
        >
          {title}
        </span>
      )}
    </ListItem>
  )
}

function DrawerNestedListItem({
  index,
  title,
  Icon,
  isActive,
  withTitle,
  onClick,
}) {
  const classes = useStyles()

  return (
    <>
      <ListItem
        button
        key={title + index}
        onClick={onClick}
        className={!isActive ? 'itemInactive' : 'itemInactive'}
        disableGutters
      >
        <ListItemIcon className="listItemIcon">
          <Icon className="listIcon" />
        </ListItemIcon>
        {withTitle && (
          <span
            className={isActive ? 'listItemNestedTitle' : 'listItemNestedTitle'}
          >
            {title}
          </span>
        )}
      </ListItem>
    </>
  )
}

export default Drawer
export { DrawerListItem, DrawerNestedListItem }
