/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { TextInput } from '../../Components'
import { mainImagePng, oneUp } from '../../Themes/Images'
import { useAuth } from '../../Global'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { colors } from '../../Themes'
import { PrimaryButton } from '../../Components/Button/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useStyles from './VerifyCode.styles'

function Verify() {
  const classes = useStyles()
  const { isAuthenticated, isLoginIn, codeError, setAUthCodeValues, verify } = useAuth()
  const [code, setCode] = useState('')
  const [disabled, setDisabled] = useState(false)

  const history = useHistory()

  const keyPress = (e) => {
    e.preventDefault()
    verifyCode()
    setCode('')
  }

  const toLogin = () => history.push('/login')

  const verifyCode =  () => {
    verify(code.trim(), toLogin)
  }

  const handleChange = (e) => {
    setCode(e.target.value.toUpperCase())

  }


  useEffect(() => {
    if (
      code
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    code
  
  ])

  
  useEffect(() => {
    if (codeError) {
      setAUthCodeValues((prev)=>{
        return {
          ...prev,
          codeError: null
        }
      })
    }
  }, [code])


  if (isAuthenticated) {
    return <Redirect 
      to={{
        pathname: '/dashboard',
        state: { fromLogin: true }
      }}
    />
  }

  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.loginAnimationContainer}>
          {/* <Text size='h2' color={colors.white} weight='bold'>INTELLIGENT INSIGHTS</Text> */}
          <img src={mainImagePng} alt='mainImage'/>
        </div>
        <form className={classes.loginForm} onSubmit={keyPress}>
          <div className={classes.loginWrapper}>
            <img src={oneUp} alt='' style={{ width: 170, height: 'auto', objectFit: 'contain' }} />
            
            <h1 style={{ color: colors.black }}>Enter 2FA code</h1>
            <TextInput
              name='code'
              costumizedInputStyles={classes.input}
              label='Code'
              value={code.toUpperCase()}
              onChange={(value) => handleChange(value)}
            //   onBlur={handleOnBlur}
            />
            
            <div className={classes.loginButtons}>
              <div className={classes.infoWrapper} >
                {/* <p className={classes.infoText}>
                Forgot password?
                </p>
                <Link className={classes.forgotPassword} to="/reset-password">
                  <span style={{ fontWeight: 'bold' }}>Reset it now</span>
                </Link> */}
              </div>
              <PrimaryButton
                type="submit"
                disabled={disabled || isLoginIn}
                // loading={isLoginIn}
                style={
                  disabled 
                    ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
                    : { backgroundColor: '#58C2D7', minHeight: 50 }
                }
                className={classes.loginButton}
              >
             Verify
              </PrimaryButton>
              
            </div>
            <Link
              style={{
                display: 'flex',
                textDecoration: 'none',
                color: colors.black,
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 15
              }}
              to="/login"
            >
              <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
              <span style={{ fontWeight: 'bold', color: colors.black }} className="logoutDrawer">Back to Login</span>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default Verify
