import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.black, 
    color: 'white', 
    zIndex: 1300,
    paddingTop: 10,
    paddingBottom: 10,
    paddingInline: 30, 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky', 
    top: 0,
    minHeight: 40,
    [theme.breakpoints.down(760)]: {
      paddingInline: 15, 
    },
  },
  iconContainer: {
    display: 'flex', 
    justifyContent: 'space-between',
    gap: 20, 
    position: 'absolute',
    right: 5,
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      gap: 0, 
    },
  },
  flex: {
    display: 'flex'
  }
}))

export default useStyles