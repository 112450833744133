/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { TextInput, Password } from '../../Components'
import {  mainImagePng, oneUp } from '../../Themes/Images'
import { useAuth } from '../../Global'
import { Link, useHistory } from 'react-router-dom'
import { colors } from '../../Themes'
import { PrimaryButton } from '../../Components/Button/Button'
import useStyles from './Login.styles'

const Expire = ({ delay, children }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return visible ? <div>{children}</div> : <div />
}


function Login() {
  const classes = useStyles()
  const { login: LoginGlobal, isLoginIn, loginError, setLoginValues, codeError } = useAuth()
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(false)
  const keyPress = (e) => {
    e.preventDefault()
    login()
  }
  const history = useHistory()
  const toVerify = () => history.push('/verify')

  const login =  () => {
    LoginGlobal(username.trim(), password.trim(), toVerify)
  }
  



  useEffect(() => {
    if (
      username &&
      password 
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    username,
    password,
  
  ])

  
  useEffect(() => {
    if (loginError) {
      setLoginValues((prev)=>{
        return {
          ...prev,
          loginError: null
        }
      })
    }
  }, [username, password])


  // if (isAuthenticated) {
  //   return <Redirect 
  //     to={{
  //       pathname: '/dashboard',
  //       state: { fromLogin: true }
  //     }}
  //   />
  // }

  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.loginAnimationContainer}>
          {/* <Text size='h2' color={colors.white} weight='bold'>INTELLIGENT INSIGHTS</Text> */}
          <img src={mainImagePng} alt='mainImage'/>
        </div>
        <form className={classes.loginForm} onSubmit={keyPress}>
          <div className={classes.loginWrapper}>
            <img src={oneUp} alt='' style={{ width: 170, height: 'auto', objectFit: 'contain' }} />
            <h1 style={{ color: colors.black }}>Log in</h1>
            <TextInput
              inputLabelStyle={{ fontSize: 16 }}
              label="Email"
              value={username} 
              setValue={setUserName}
            />
            <Password  label="Password" value={password} setValue={setPassword} />
            {Boolean(loginError) && <span className="loginError">{loginError}</span>}
            {Boolean(codeError) && <Expire delay='3000'><span className="loginError">{codeError}</span></Expire>}
            <div className={classes.loginButtons}>
              <div className={classes.infoWrapper} >
                <p className={classes.infoText}>
                Forgot password?
                </p>
                <Link className={classes.forgotPassword} to="/reset-password">
                  <span style={{ fontWeight: 'bold', color: colors.black }}>Reset it now</span>
                </Link>
              </div>
              <PrimaryButton
                type="submit"
                disabled={disabled || isLoginIn}
                loading={isLoginIn}
                style={
                  disabled ||isLoginIn
                    ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
                    : { backgroundColor: '#58C2D7', minHeight: 50 }
                }
                className={classes.loginButton}
              >
               Send code
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Login
