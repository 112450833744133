/* eslint-disable camelcase */
import React from 'react'
import useStyles from './RenderPasswordCondition.styles'

const RenderPasswordCondition = ({ validatePassowrd }) => {
  const classes = useStyles()
  
  let lowerAndUpperCase=validatePassowrd.upperCase && validatePassowrd.lowerCase

  return (
    <>
      <div className={classes.RenderPasswordConditionContainer}>
        <div className={classes.RenderPasswordWrapper}>
          <div className={classes.RenderPasswordRow}>
            <div
              className={
                classes[
                  'dotSucces_' +
                    validatePassowrd.minimumLength +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            />
            <p
              className={
                classes[
                  'textSucces_' +
                    validatePassowrd.minimumLength +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            >
              at least 8 characters long
            </p>
          </div>
          <div className={classes.RenderPasswordRow}>
            <div
              className={
                classes[
                  'dotSucces_' +
                    validatePassowrd.number +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            />
            <p
              className={
                classes[
                  'textSucces_' +
                    validatePassowrd.number +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            >
              at least one number
            </p>
          </div>
        </div>
        <div className={classes.RenderPasswordWrapper}>
          <div className={classes.RenderPasswordRow}>
            <div
              className={
                classes[
                  'dotSucces_' +
                  lowerAndUpperCase +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            />

            <p
              className={
                classes[
                  'textSucces_' +
                  lowerAndUpperCase +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            >
              at least one uppercase and lowercase
            </p>
          </div>
          <div className={classes.RenderPasswordRow}>
            <div
              className={
                classes[
                  'dotSucces_' +
                    validatePassowrd.symbols +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            />
            <p
              className={
                classes[
                  'textSucces_' +
                    validatePassowrd.symbols +
                    '_disabled_' +
                    validatePassowrd.focused
                ]
              }
            >
              at least one symbol (e.g. . ! ?)
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default RenderPasswordCondition
