import React from 'react'
import Loading from 'react-lottie'
import {
  Link
} from 'react-router-dom'
import { useAuth } from '../../Global'
import { Pressable } from '../../Components/Button/Button'

import { notFoundAnim } from '../../Themes/Images'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: notFoundAnim,
  rendererSettings: {
    className: 'loading',
  },
}

function NotFound() {
  const { isAuthenticated } = useAuth()
  return (
    <div className="loadingContainer">
      <Loading options={LOADING_OPTIONS} height={250} />
      <span className='notFoundText'>
        The requested page could not be found on our servers
      </span>
      <Pressable>
        {isAuthenticated
          ? <Link to="/dashboard" className='linkButton'>Open Homepage</Link>
          : <Link to="/login" className='linkButton'>Login to continue</Link>
        }
      </Pressable>
    </div>
  )
}

export default NotFound