import React from 'react'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { withStyles } from '@material-ui/core/styles'
import { FilledInput, useMediaQuery, useTheme } from '@material-ui/core'
import Text from '../../Text'
import { inputStylesPass, useStylesPass } from './Password.styles'

const Input = withStyles(inputStylesPass)((props) => <FilledInput disableUnderline={false} {...props} />)

function Password({ label, value, disabled, setValue, costumizedInputStyles, costumizedInputRootStyles, inputLabelStyle, ...rest }) {
  const classes = useStylesPass()
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false)
  const theme = useTheme()
  const bigScreen = useMediaQuery(theme.breakpoints.up('2000'))

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleClickShowPassword = () => {
    setPasswordVisibility((visible) => !visible)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <div className={clsx(bigScreen ? classes.root2 : classes.root, costumizedInputRootStyles)}>
      <FormControl    
        className={clsx(classes.input, costumizedInputStyles)}
        variant="filled"
      >
        <InputLabel
          htmlFor="filled-adornment-password"
          className="passwordLabel"
          style={inputLabelStyle}
        >
          {<Text size='subnote' color='hsl(0, 0%, 50%)'>{label}</Text>}
        </InputLabel>
        <Input
          id="filled-adornment-password"
          type={isPasswordVisible ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
          autoComplete="off"
          disabled={disabled}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          {...rest}
        />
      </FormControl>
    </div>
  )
}

Password.defaultProps = {
  disabled: false,
}

export default Password
